<template>
  <BaseLayout :title="title" v-bind="style">
    <div class="content" :class="page" v-html="content" v-show="!show"></div>
    <van-empty description='暂无内容' v-show="show"></van-empty>
  </BaseLayout>
</template>

<style scoped>
.content {
  height: 400px;
  margin-top: 20px;
  padding: 20px 20px;
  background: #F9F9F9;
  border-radius: 15px 15px 0 0;
  font-size: 16px;
  line-height: 28px;
}
.feature {
  margin: 0 0 60px 0;
  padding: 0;
  background: #FFF;
  border-radius: 0;
}
</style>

<script>
import {mapState} from 'vuex'
import BaseLayout from '@/components/BaseLayout.vue'

export default {
  components: {BaseLayout},

  props: {
    page: String,
  },

  data(){
    const title = "";
    const content = "";
    const show = false;
    return {title, content, show};
  },

  mounted() {
    if (typeof this.config[this.page] == "undefined"){
      this.show = true;
      return;
    }
    this.title = this.config[this.page]["name"];
    this.content = this.config[this.page]["value"];
    this.$store.commit("changeSticky", false);
  },

  computed: {
    style() {
      let bg = "linear-gradient(180deg, #EEE, #F9F9F9 10%, #F9F9F9)";
      let fg = "#000";
      let height = "60px";
      if ("help".indexOf(this.page) > -1){
        bg = "url('" + require('../assets/bg3.jpg') + "') top right no-repeat";
        fg = "#FFF";
      }
      if ("intro,guide".indexOf(this.page) > -1){
        bg = "url('" + require('../assets/bg4.jpg') + "') top right no-repeat";
        fg = "#FFF";
      }
      if (this.page == "feature"){
        bg = "#FFF";
        fg = "#FFF";
        height = 0;
      }
      return {bg:bg, fg:fg, height:height};
    },
    ...mapState(["config"]),
  },

  methods: {
    onMore() {
      console.log("More");
    }
  }
}
</script>
